.phoneInput > input {
  display: none;
  width: 80px;
  border: none;
}
.phoneInput {
  width: 50px !important;
}

.profile-photo {
  border-radius: 10px;
}
.bTn {
  background: linear-gradient(
    180deg,
    rgba(17, 68, 102, 0.83) 0%,
    rgb(40, 85, 168) 0%,
    rgb(21, 42, 91) 69%,
    rgba(19, 59, 178, 0.819) 100%
  ) !important;
  cursor: pointer;
  color: #ffff !important;
}
.bTn:hover {
  background: linear-gradient(
    180deg,
    rgba(17, 68, 102, 0.83) 0%,
    rgb(67, 121, 221) 0%,
    rgb(34, 69, 151) 69%,
    rgba(46, 86, 206, 0.819) 100%
  ) !important;
  color: #ffff !important;
}
.ebsj {
  background-color: #2f4cdd80;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.search-input {
  height: 40px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 5px;
  margin-left: 6px;
  padding: 5px;
}
.search-container {
  position: relative;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #555;
  cursor: pointer;
}
.range-picker-input {
  height: 55px;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 10px;
  padding: 3px;
  background-color: #fff;
  /* opacity: 0.6; */
  margin-right: 15px;
}

.report-modal .modal-dialog {
  max-width: 800px !important;
}
